
import { defineComponent } from '@vue/composition-api';
import { LinkButton } from '@fc/angie-ui';
import MediaBackground from '~/components/storyblok/MediaBackground/MediaBackground.vue';
import DownloadAppIcons from '~/components/storyblok/DownloadAppIcons/DownloadAppIcons.vue';

export default defineComponent({
  name: 'ConsoleATF',
  components: {
    MediaBackground,
    LinkButton,
    DownloadAppIcons,
  },
  setup() {
    return {
      videoObj: [{
        src: 'https://cdn.joinfightcamp.com/videos/fc-antm-30-aug-001.mp4 ',
        type: 'video/mp4',
      }, {
        src: 'https://cdn.joinfightcamp.com/videos/fc-antm-30-aug-001.webm',
        type: 'video/webm',
      }],
      headlineText: 'FightCamp is Boxing, Kickboxing & More',
      firstFrameImageUrl: 'https://a.storyblok.com/f/152150/2048x858/269aebbf3b/fc-antm-30-aug-first-frame-001.jpg',
      mp4MobileVideo: '',
      mp4Video: 'https://cdn.joinfightcamp.com/videos/fc-antm-30-aug-001.mp4 ',
      webmMobileVideo: '',
      webmVideo: 'https://cdn.joinfightcamp.com/videos/fc-antm-30-aug-001.webm',
      videoComponent: {
        _uid: 'e473f740-26f4-454d-afe1-108bb14879cd',
        codeId: '',
        component: 'Video',
        isOverlay: false,
        playsAudio: false,
        hasControls: false,
        hasPlayIcon: false,
        mp4VideoUrl: 'https://cdn.joinfightcamp.com/videos/fc-antm-30-aug-001.mp4 ',
        webmVideoUrl: 'https://cdn.joinfightcamp.com/videos/fc-antm-30-aug-001.webm',
        firstFrameImage: {
          id: 16590149,
          alt: '',
          name: '',
          focus: '',
          title: '',
          source: '',
          filename: 'https://a.storyblok.com/f/152150/2048x858/269aebbf3b/fc-antm-30-aug-first-frame-001.jpg',
          copyright: '',
          fieldtype: 'asset',
          meta_data: {},
          is_private: false,
          is_external_url: false,
        },
        mp4MobileVideoUrl: '',
        webmMobileVideoUrl: '',
        mobileVideoPlayback: '',
        tabletUpVideoPlayback: '',
      },
      appleBranchLink: {
        url: 'https://fightcamp.app.link/y3nZxmYIDob',
        linktype: 'url',
        fieldtype: 'multilink',
        cached_url: 'https://fightcamp.app.link/y3nZxmYIDob',
      },
      googleBranchLink: {
        id: '',
        url: 'https://fightcamp.app.link/76ZCQs5IDob',
        linktype: 'url',
      },
      iconDimensions: {
        apple: {
          mobileHeight: '40px',
          mobileWidth: '120px',
          tabletUpHeight: '45px',
          tabletUpWidth: '154px',
        },
        google: {
          mobileHeight: '58px',
          mobileWidth: '149px',
          tabletUpHeight: '69px',
          tabletUpWidth: '194px',
        },
      },
    };
  },
});
