













import { defineComponent, PropType } from '@vue/composition-api';
import { storyBlokCompResp } from '~/selectors/types';

export default defineComponent({
  name: 'InternalPage',
  inheritAttrs: false,
  props: {
    body: {
      type: Array as PropType<storyBlokCompResp[]>,
      default: () => [],
    },
  },
});
