









import { defineComponent } from '@vue/composition-api';
import { metaData } from '~/configuration/metaData';
import { CMSService } from '~/services/storyBlok/cmsService';
import ConsoleATF from '~/components/storyblokUnique/ConsoleATF.vue';

export default defineComponent({
  name: 'Home',
  head: {
    ...metaData.home,
  },
  components: {
    ConsoleATF,
  },
  async asyncData({ $storyapi, error }: any) {
    const storyBlok = new CMSService({ cmsService: $storyapi });
    let storyResp: any[] = [];
    let storyRespId;

    await storyBlok.get('home', { resolve_relations: 'ReviewsGrid.reviews' })
      .then((resp) => {
        storyRespId = resp?.data?.story?.id;
        storyResp = resp?.data?.story?.content;
      })
      .catch((err) => {
        error({ message: err?.message });
      });
    return { storyResp, storyRespId };
  },
});
